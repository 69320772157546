'use client';

import {
    Dispatch
    , SetStateAction
} from 'react';

// MUI
import {
    Alert
    , AlertTitle
    , Snackbar
} from '@mui/material';
import { Wifi as WifiIcon } from '@mui/icons-material';

// Utils
import { pxrem } from '@/utils/pxrem';

interface Props {
    isOnline: boolean;
    setIsOnline: Dispatch<SetStateAction<boolean>>;
}

export const OnlineSnackbar = ( {
    isOnline
    , setIsOnline
}: Props ) => {

    return (
        <Snackbar
            open={ isOnline }
            autoHideDuration={ 3000 }
            onClose={ () => setIsOnline( false ) }
            anchorOrigin={ {
                vertical: 'top'
                , horizontal: 'center'
            } }
            sx={ { marginTop: pxrem( 60 ) } }
        >
            <Alert
                icon={ (
                    <WifiIcon
                        sx={ {
                            color: theme => theme.palette.common.white
                            , fontWeight: 600
                        } }
                    />
                ) }
                sx={ theme => ( {
                    backgroundColor: theme.palette.primary.main
                    , color: theme.palette.common.white
                    , borderRadius: pxrem( 12 )
                    , padding: pxrem( 10, 20 )
                    , minWidth: pxrem( 330 )
                } ) }
            >
                <AlertTitle sx={ { fontWeight: 600 } }>
                    Online
                </AlertTitle>
                    You are connected to the internet.
            </Alert>
        </Snackbar>
    );
};

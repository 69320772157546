'use client';

// MUI
import {
    Alert
    , AlertTitle
    , Box
    , Snackbar
} from '@mui/material';
import { WifiOff as WifiOffIcon } from '@mui/icons-material';

// Utils
import { pxrem } from '@/utils/pxrem';

interface Props {
    isOffline: boolean;
}

export const OfflineSnackbar = ( { isOffline }: Props ) => {

    return (
        <Snackbar
            open={ isOffline }
            anchorOrigin={ {
                vertical: 'top'
                , horizontal: 'center'
            } }
            sx={ { marginTop: pxrem( 60 ) } }
        >
            <Alert
                icon={ (
                    <WifiOffIcon
                        sx={ {
                            color: theme => theme.palette.common.white
                            , fontWeight: 600
                        } }
                    />
                ) }
                sx={ theme => ( {
                    backgroundColor: theme.palette.gray.dark
                    , color: theme.palette.common.white
                    , borderRadius: pxrem( 12 )
                    , padding: pxrem( 10, 20 )
                    , minWidth: pxrem( 330 )
                } ) }
            >
                <AlertTitle sx={ { fontWeight: 600 } }>
                    Offline
                </AlertTitle>
                    Please connect to the internet.
                <Box component='br' />
                    Actions will not save while offline.
            </Alert>
        </Snackbar>
    );
};

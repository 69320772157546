import React from 'react';

// MUI
import {
    Box
    , Typography
} from '@mui/material';

// Utils
import { pxrem } from '@/utils/pxrem';

interface Props {
    toastIcon: React.ReactNode;
    message: string;
}

export const ToastIconAndMessage = ( {
    toastIcon
    , message
}: Props ) => {
    return (
        <Box
            component='span'
            sx={ {
                display: 'flex'
                , alignItems: 'center'
                , textTransform: 'none'
            } }
        >
            <Box
                sx={ {
                    marginRight: pxrem( 10 )
                    , marginTop: pxrem( 5 )
                } }
            >
                { toastIcon ?? null }
            </Box>
            <Typography sx={ { fontWeight: 500 } }>
                { message }
            </Typography>
        </Box>
    );
};

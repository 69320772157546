import React from 'react';

// MUI
import {
    Button
    , IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface Props {
    closeHandler?: () => void;
    showCloseButton?: boolean;
    actionText?: string;
    actionHandler?: () => void;
}

export const ActionButtons = ( {
    closeHandler
    , showCloseButton
    , actionHandler
    , actionText
}: Props ) => {

    if ( closeHandler && showCloseButton ) {
        return (
            <IconButton
                key='close'
                aria-label='close'
                color='inherit'
                onClick={ closeHandler }
            >
                <CloseIcon />
            </IconButton>
        );
    } else if ( actionHandler ) {
        return (
            <Button
                variant='text'
                aria-label={ actionText }
                color='inherit'
                onClick={ actionHandler }
            >
                { actionText }
            </Button>
        );
    } else {
        return null;
    }
};

import React from 'react';

// MUI
import {
    SnackbarContent
    , Theme
} from '@mui/material';
import {
    CheckCircle as SuccessIcon
    , Error as ErrorIcon
    , Warning as WarningIcon
} from '@mui/icons-material';

// Libraries
import { CustomContentProps } from 'notistack';

// Components
import { ToastIconAndMessage } from '../shared/ToastIconAndMessage/ToastIconAndMessage';
import { ActionButtons } from '../shared/ActionButtons/ActionButtons';

interface DefaultSnackbarProps extends CustomContentProps {
    icon?: React.ReactNode;
    closeHandler?: () => void;
    showCloseButton?: boolean;
    actionText?: string;
    actionHandler?: () => void;
}

export const BaseSnackbar = React.forwardRef<HTMLDivElement, DefaultSnackbarProps>( ( {
    id
    , ...props
}, ref ) => {

    const getSnackbarStyle = ( theme: Theme ) => {
        switch ( props.variant ) {
            case 'success':
                return {
                    backgroundColor: theme.palette.primary.main
                    , color: theme.palette.common.white
                };
            case 'warning':
                return {
                    backgroundColor: theme.palette.warning.main
                    , color: theme.palette.common.white
                };
            case 'error':
                return {
                    backgroundColor: theme.palette.error.main
                    , color: theme.palette.common.white
                };
            default:
                return {};
        }
    };

    const getSnackbarIcon = () => {
        switch ( props.variant ) {
            case 'success':
                return <SuccessIcon />;
            case 'warning':
                return <WarningIcon />;
            case 'error':
                return <ErrorIcon />;
            default:
                return props.icon;
        }
    };

    return (
        <SnackbarContent
            ref={ ref }
            id={ id.toString() }
            sx={ theme => ( {
                fontWeight: 500
                , ...getSnackbarStyle( theme )
            } ) }
            message={ (
                <ToastIconAndMessage
                    toastIcon={ getSnackbarIcon() }
                    message={ props.message as string }
                />
            ) }
            action={ (
                <ActionButtons
                    closeHandler={ props.closeHandler }
                    showCloseButton={ props.showCloseButton }
                    actionText={ props.actionText }
                    actionHandler={ props.actionHandler }
                />
            ) }
        />
    );
} );

BaseSnackbar.displayName = 'DefaultSnackbar';
